@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src:
    local('Pretendard'),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src:
    local('Pretendard'),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src:
    local('Pretendard'),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src:
    local('Pretendard'),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  src:
    local('Pretendard'),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff) format('woff2');
}

:root {
  --color-admin-main: #0081ff;
  --color-admin-navy: #134575;
  --color-dark-1: #3a3a3c;
  --color-dark-2: #6b7588;
  --color-dark-3: #8f90a6;
  --color-dark-4: #c7c9d9;
  --color-light-1: #dde5e9;
  --color-light-2: #ebebf0;
  --color-light-3: #f2f2f5;
  --color-light-4: #fafafc;
  --color-admin-border: #ebebf0;
  --border-radius: 6px;
}

html,
body,
#root {
  width: 100%;
  height: auto;
  min-height: 100vh;
  min-width: 280px;
  text-wrap: nowrap;
  font-family: Pretendard !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

body::-webkit-scrollbar {
  width: 0;

}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /*text-decoration: none !important;*/
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: normal;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@-webkit-keyframes slideIn {
  from {
    transform: translate(0, 500px);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    transform: translate(0, 500px);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
