.admin-datePicker .react-datepicker-wrapper {
  user-select: none;
}
.react-datepicker {
  font-family: 'Pretendard';
}


.react-datepicker-wrapper {
  position: relative;
  display: flex;
}

.admin-datePicker .react-datepicker-wrapper .react-datepicker__input-container .datepicker_input_wrapper {
  position: relative;
  display: flex;
}
  .admin-datePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .datepicker_input_wrapper
  .__calendar_icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.admin-datePicker .react-datepicker-wrapper .react-datepicker__input-container .datepicker_input_wrapper > input {
  width: 285px;
  height: 42px;
  padding: 11px 20px 11px 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  border: 1px solid var(--color-admin-border);
  border-radius: 6px;
  color: var(--color-dark-1);
  cursor: pointer;
}
  .admin-datePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .datepicker_input_wrapper
  > input:focus-visible {
  outline: none;
}

/*111111*/
.admin-datePicker .react-datepicker-wrapper .react-datepicker__input-container .datepicker_input_wrapper_payView {
  position: relative;
  display: flex;
}
.admin-datePicker
.react-datepicker-wrapper
.react-datepicker__input-container
.datepicker_input_wrapper_payView
.__calendar_icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.admin-datePicker .react-datepicker-wrapper .react-datepicker__input-container .datepicker_input_wrapper_payView  > input {
  width: 485px;
  height: 42px;
  padding: 11px 20px 11px 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  border: 1px solid var(--color-admin-border);
  border-radius: 6px;
  color: var(--color-dark-1);
  cursor: pointer;
}
.admin-datePicker
.react-datepicker-wrapper
.react-datepicker__input-container
.datepicker_input_wrapper_payView
> input:focus-visible {
  outline: none;
}

/*111111*/

.datepicker_input_wrapper_payView_underline {
  position: relative;
  display: flex;
}
.datepicker_input_wrapper_payView_underline .__calendar_icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.datepicker_input_wrapper_payView_underline > input {
  width: 420px;
  height: 42px;
  padding: 11px 20px 16px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  border-bottom: 1px solid var(--color-admin-border);
  color: var(--color-dark-1);
  cursor: pointer;
}
.datepicker_input_wrapper_payView_underline > input:focus-visible {
  outline: none;
}

/*222*/
.admin-datePicker .react-datepicker__tab-loop .react-datepicker-popper {
  padding-top: 1px;
  width: 328px;
}
.admin-datePicker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker {
  width: 328px;
  border: 1px solid #ebebf0;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}
.admin-datePicker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
  display: none;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container {
  width: 328px;
  padding: 20px 24px;
  border-bottom: 1px solid #e4e7ec;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header {
  padding: 0px;
  background-color: #fff;
  border-bottom: 0;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .move_date__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .move_date__wrapper
  .date {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .move_date__wrapper
  button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .display_date__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-dark-1);
  margin-bottom: 12px;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .display_date__wrapper
  input {
  width: 128px;
  height: 44px;
  padding: 10px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  border: 1px solid #d0d5dd;
  color: var(--color-dark-1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  text-align: center;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .display_date__wrapper
  input:focus-visible {
  outline: none;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names {
  margin-bottom: 4px;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #344054;
  margin: 0;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month {
  margin: 0;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week {
  margin-bottom: 4px;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week:last-child {
  margin-bottom: 0;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  width: 40px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #344054;
  margin: 0;
  border-radius: 0;
  background-color: transparent;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day
  .__day {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
}

.admin-datePicker
.react-datepicker__tab-loop
.react-datepicker-popper
.react-datepicker
.react-datepicker__month-container
.react-datepicker__month
.react-datepicker__week
.react-datepicker__day
.__day.selected {
  position: relative;
  background-color: #d0eaff;
  border-radius: 50%;
}

.admin-datePicker
.react-datepicker__tab-loop
.react-datepicker-popper
.react-datepicker
.react-datepicker__month-container
.react-datepicker__month
.react-datepicker__week
.react-datepicker__day
.__day.selected:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--color-admin-navy);
  border-radius: 50%;
  bottom: 4px;
  left: calc(50% - 2.5px);
}

.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day:focus-visible {
  outline: 0;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--outside-month {
  color: #667085;
  font-weight: 400;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--disabled {
  color: #c7c9d9;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--today {
  position: relative;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--today:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--color-admin-navy);
  border-radius: 50%;
  bottom: 4px;
  left: calc(50% - 2.5px);
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--today
  .__day {
  background-color: #f2f4f7;
  border-radius: 20px;
  font-weight: 500;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range {
  background-color: #f9fafb;
  font-weight: 500;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start
  .__day {
  border-radius: 50%;
  background-color: var(--color-admin-navy);
  color: #fff;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end
  .__day {
  border-radius: 50%;
  background-color: var(--color-admin-navy);
  color: #fff;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range {
  background-color: #f9fafb;
  font-weight: 500;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start
  .__day {
  border-radius: 50%;
  background-color: var(--color-admin-navy);
  color: #fff;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end
  .__day {
  border-radius: 50%;
  background-color: var(--color-admin-navy);
  color: #fff;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day:first-child.react-datepicker__day--weekend {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day:last-child.react-datepicker__day--weekend {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__children-container {
  margin: 0;
  padding: 0;
  width: 100%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__children-container
  .datepicker_bottom_btn__wrapper {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__children-container
  .datepicker_bottom_btn__wrapper
  > button {
  width: 142px;
  height: 44px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}
.admin-datePicker
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__children-container
  .datepicker_bottom_btn__wrapper
  > button.cancel_btn {
  color: #344054;
  border: 1px solid #c7c9d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
